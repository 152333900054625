import dayjs from 'dayjs';

export const processedTemplateContent = async ({
  templateType,
  templateContent,
  collectionData,
  dispatchAPI,
  t
}) => {
  let anounce = null;
  let customerInvoice = null;
  if (templateType === 'UNPAID_INVOICE_REMINDER') {
    const { data: subscriptionInvoiceData } = await dispatchAPI('GET', {
      url: `/customerinvoices?supervision=${collectionData._id}&type=BALANCE`
    });

    if (subscriptionInvoiceData.length) {
      customerInvoice = subscriptionInvoiceData[0];
    } else {
      const { data: customerInvoiceData } = await dispatchAPI('GET', {
        url: `/customerinvoices/${collectionData._id}`
      });

      customerInvoice = customerInvoiceData;
    }
  }

  switch (templateType) {
    case 'UNPAID_INVOICE_REMINDER':
      return templateContent
        .replace('{{total_ttc}}', `${customerInvoice?.total_ttc} €`)
        .replace(
          '{{due_date}}',
          dayjs(customerInvoice?.due_date).format('DD/MM/YYYY')
        )
        .replace(
          '{{civility}}',
          collectionData?.customer?.civility
            ? t(
                `owners.tags.full_civility_${collectionData?.customer?.civility}`
              )
            : ''
        );
    case 'SEND_SUBSCRIPTION_REMINDER': {
      const { data } = await dispatchAPI('GET', {
        url: `/customerinvoices/${collectionData._id}`
      });

      customerInvoice = data;

      return templateContent.replace(
        '{{invoice_reference}}',
        `${customerInvoice?.reference} €`
      );
    }
    case 'SEND_INFORMATION_OWNER':
      return templateContent
        .replace(
          '{{animal_number}}',
          `${
            collectionData?.main_informations?.animals.length === 1
              ? 'votre animal'
              : 'vos animaux'
          } `
        )
        .replace(
          '{{start_date}}',
          dayjs(collectionData?.main_informations?.start_date).format(
            'dddd D MMMM YYYY'
          )
        )
        .replace(
          '{{end_date}}',
          dayjs(collectionData?.main_informations?.end_date).format(
            'dddd D MMMM YYYY'
          )
        )
        .replace(
          '{{arrival_date}}',
          dayjs(collectionData?.main_informations?.start_date)
            .subtract(1, 'day')
            .format('dddd D MMMM YYYY')
        );
    case 'SEND_INFORMATION_PENSIONER': {
      const { data } = await dispatchAPI('GET', {
        url: `/anounces/form?supervision=${collectionData._id}&populate=supervision`
      });

      anounce = data[0];

      return templateContent
        .replace('{{supervision_reference}}', collectionData?.reference)
        .replace(
          '{{start_date}}',
          dayjs(collectionData?.main_informations?.start_date).format(
            'dddd D MMMM YYYY'
          )
        )
        .replace(
          '{{end_date}}',
          dayjs(collectionData?.main_informations?.end_date).format(
            'dddd D MMMM YYYY'
          )
        )
        .replace(
          '{{arrival_date}}',
          dayjs(collectionData?.main_informations?.start_date)
            .subtract(1, 'day')
            .format('dddd D MMMM YYYY')
        )
        .replace(
          '{{owner_civility}}',
          collectionData?.main_informations?.owner?.civility
            ? t(
                `owners.tags.${collectionData?.main_informations?.owner?.civility}`
              )
            : ''
        )
        .replace(
          '{{owner_last_name}}',
          collectionData?.main_informations?.owner?.last_name || ''
        )
        .replace(
          '{{owner_first_name}}',
          collectionData?.main_informations?.owner?.first_name || ''
        )
        .replace('{{anounce_description}}', anounce?.description || '');
    }
    case 'CONTRACT_PENSIONER':
      return templateContent
        .replace('{{supervision_reference}}', collectionData?.reference)
        .replace(
          '{{owner_civility}}',
          collectionData?.main_informations?.owner?.civility
            ? t(
                `owners.tags.${collectionData?.main_informations?.owner?.civility}`
              )
            : ''
        )
        .replace(
          '{{owner_last_name}}',
          collectionData?.main_informations?.owner?.last_name || ''
        )
        .replace(
          '{{owner_first_name}}',
          collectionData?.main_informations?.owner?.first_name || ''
        );
    case 'CONTRACT_OWNER':
      return templateContent;
    case 'OWNER_SUPERVISION_VALIDATION': {
      let pensionersNames = '';

      if (collectionData?.pensioners?.length === 1) {
        const pensioner = collectionData.pensioners[0];
        pensionersNames = `${pensioner?.last_name || ''} ${
          pensioner?.first_name || ''
        }`;
      } else if (collectionData?.pensioners?.length > 1) {
        const firstPensioner = collectionData.pensioners[0];
        const secondPensioner = collectionData.pensioners[1];
        pensionersNames = `${firstPensioner?.last_name || ''} ${
          firstPensioner?.first_name || ''
        } et ${secondPensioner?.last_name || ''} ${
          secondPensioner?.first_name || ''
        }`;
      }
      return templateContent
        .replace('{{supervision_reference}}', collectionData?.reference)
        .replace('{{pensioner_name}}', pensionersNames || '');
    }
    case 'PENSIONER_SUPERVISION_VALIDATION':
      return templateContent
        .replace('{{supervision_reference}}', collectionData?.reference)
        .replace(
          '{{start_date}}',
          dayjs(collectionData?.main_informations?.start_date).format(
            'dddd D MMMM YYYY'
          )
        )
        .replace(
          '{{end_date}}',
          dayjs(collectionData?.main_informations?.end_date).format(
            'dddd D MMMM YYYY'
          )
        );

    case 'SEND_RESERVATION_REQUESTS_LIST': {
      return templateContent.replace(
        '{{reference}}',
        collectionData?.reference
      );
    }

    default:
      return templateContent;
  }
};
