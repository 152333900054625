/**
 * Formats the given start and end dates to a readable string format.
 * @function
 *
 * @param {string} start_date - The start date in a format recognized by the Date constructor.
 * @param {string} end_date - The end date in a format recognized by the Date constructor.
 * @returns {Object} An object containing the formatted start and end dates.
 * @returns {string} return.start_date - The formatted start date in the format "DD Month YYYY".
 * @returns {string} return.end_date - The formatted end date in the format "DD Month YYYY".
 */
export const dateFormat = (start_date, end_date) => {
  const startDate = new Date(start_date);
  const endDate = new Date(end_date);

  const options = { month: 'long' };

  startDate.setDate(startDate.getDate() - 1);

  const formattedStartDate = `${startDate.getDate()} ${startDate.toLocaleDateString(
    'default',
    options
  )} ${startDate.getFullYear()}`;
  const formattedEndDate = `${endDate.getDate()} ${endDate.toLocaleDateString(
    'default',
    options
  )} ${endDate.getFullYear()}`;

  return { start_date: formattedStartDate, end_date: formattedEndDate };
};
