import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import { useParams } from 'react-router-dom';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainer/CreateUpdateContainer';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useGeneralFields } from './fields/generalFields';
import { useConfig } from './utils/CreateUpdateConfig';
import { FormExtra } from './formExtra';
import { useUnlockEditProfile } from '../../../utils/useUnlockEditProfile';
import { useHandleProfileLock } from '../../../utils/useHandleProfileLock';

/**
 * The `CreateUpdateOwner` component is used to create or update an owner record.
 * It leverages several hooks to manage state and uses the `CreateUpdateContainer` to render
 * the UI for creating or updating an owner. Additional form sections are rendered using the `FormExtra` component.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.purpose - The purpose of this component, which can either be "create" or "edit".
 *
 * @example
 * @returns {React.Element} - Element who displays a form to create an owner.
 */
export const CreateUpdateOwner = ({ purpose }) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [form] = Form.useForm();
  const { id } = useParams();
  const { unlockEditProfile } = useUnlockEditProfile(id, 'owners');
  const { handleProfileLock } = useHandleProfileLock(id);

  const [petsittingAddressFileList, setPetsittingAddressFileList] = useState(
    []
  );
  const [animalsFileList, setAnimalsFileList] = useState({});
  const [internetAccess, setInternetAccess] = useState(true);
  const [environmentType, setEnvironmentType] = useState([]);
  const [photoFileList, setPhotoFileList] = useState();
  const [photoID, setPhotoID] = useState('');
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState();
  const [typeOfDwelling, setTypeOfDwelling] = useState('');

  const { basicFields, isFieldsLoading } = useGeneralFields({
    form,
    setInternetAccess,
    internetAccess,
    photoFileList,
    setPhotoFileList,
    photoID,
    setLoading,
    loading,
    country,
    setCountry
  });

  const config = useConfig({
    animalsFileList,
    setAnimalsFileList,
    petsittingAddressFileList,
    setPetsittingAddressFileList,
    dispatchAPI,
    message,
    setInternetAccess,
    environmentType,
    setEnvironmentType,
    setCountry,
    setPhotoID,
    photoID,
    photoFileList
  });

  useEffect(() => {
    if (purpose === 'edit') {
      (async () => {
        await handleProfileLock();
      })();

      window.addEventListener('beforeunload', async () => {
        await unlockEditProfile();
      });
    }

    return async () => {
      if (purpose === 'edit') {
        // clean up the event handler when the component unmounts
        window.removeEventListener('beforeunload', async () => {
          await unlockEditProfile();
        });
        // call the unlock func when the component unmounts
        await unlockEditProfile();
      }
    };
  }, []);

  return (
    <CreateUpdateContainer
      fields={basicFields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      customFormInstance={form}
      baseUrl="owners/form"
      resource="owners"
      populate="animals.animal_photo,petsitting_address.dwelling_photos,photo"
      config={config}
      formExtra={
        <FormExtra
          petsittingAddressFileList={petsittingAddressFileList}
          setPetsittingAddressFileList={setPetsittingAddressFileList}
          form={form}
          animalsFileList={animalsFileList}
          setAnimalsFileList={setAnimalsFileList}
          purpose={purpose}
          environmentType={environmentType}
          setEnvironmentType={setEnvironmentType}
          country={country}
          setCountry={setCountry}
          typeOfDwelling={typeOfDwelling}
          setTypeOfDwelling={setTypeOfDwelling}
        />
      }
    />
  );
};

CreateUpdateOwner.propTypes = {
  purpose: PropTypes.string.isRequired
};
