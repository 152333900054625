/**
 * Sorts two objects based on the value at the specified key path.
 * @function
 *
 * @param {Object} a - The first object to compare.
 * @param {Object} b - The second object to compare.
 * @param {Array<string>} keyPath - An array of strings representing the path to the value to compare.
 * @returns {number} - A negative number if `a` should come before `b`, a positive number if `a` should come after `b`, or 0 if they are considered equal.
 */
export const genericSorter = (a, b, keyPath) => {
  const getValue = (obj, path) =>
    path.reduce((acc, key) => (acc ? acc[key] : null), obj);

  const elA = getValue(a, keyPath)?.toLowerCase();
  const elB = getValue(b, keyPath)?.toLowerCase();

  if (elA && elB) {
    return elA.localeCompare(elB);
  }

  return 0;
};

/**
 * Sorts two objects based on their status property.
 * @function
 *
 * @param {Object} a - The first object to compare.
 * @param {Object} b - The second object to compare.
 * @param {string} keyPath - The key path used for translation.
 * @param {Function} t - The translation function.
 * @returns {number|null} - Returns a negative number if `a` should come before `b`,
 *                          a positive number if `a` should come after `b`,
 *                          or 0 if they are considered equal. Returns null if either status is not found.
 */
export const statusSorter = (a, b, keyPath, t) => {
  const elA = t(`${keyPath}.${a?.status}`).toLowerCase();
  const elB = t(`${keyPath}.${b?.status}`).toLowerCase();

  if (elA && elB) {
    return elA.localeCompare(elB);
  }

  return null;
};
