import { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Button, Popconfirm, Form, Dropdown, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined,
  PlusOutlined,
  DownOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { ContentCustom, PageHeaderCustom } from '../../../components';
import { routes } from '../../../utils/constants/adminRoutes';
import DetailsCards from './detailsCards/DetailsCards';
import { PhoneInterviewDrawer } from './phoneInterview/PhoneInterviewDrawer';
import { SubscriptionDrawer } from './subscription/SubscriptionDrawer';
import { getSubscription } from '../utils/getSubscription';
import { ReviewDrawer } from '../../../components/Review/ReviewDrawer';
import { MailingModal } from '../../../components/Mailing/MailingModal';
import { SubscriptionInfoModal } from './subscription/SubscriptionInfoModal';
import { ExplanatoryDocumentMenu } from './utils/ExplanatoryDocumentMenu';
import { useSocketContext } from '../../../contexts/SocketContext';
import { editingLocked } from '../../../utils/editingLocked';

export const ShowPensioner = () => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const { dispatchAPI, user } = useAuthContext();
  const { profileLock } = useSocketContext();

  const [isLoading, setIsLoading] = useState(false);
  const [pensioner, setPensioner] = useState({});
  const [refreshData, setRefreshData] = useState(false);
  const [subscriptionDrawerOpen, setSubscriptionDrawerOpen] = useState(false);
  const [subscription, setSubscription] = useState({});
  const [reviewDrawerOpen, setReviewDrawerOpen] = useState(false);
  const [questionnaireType, setQuestionnaireType] = useState('users:OWNER');
  const [supervisionRating, setSupervisionRating] = useState({});
  const [supervisions, setSupervisions] = useState([]);
  const [showReviewDrawer, setShowReviewDrawer] = useState(false);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [subscriptionInvoice, setSubscriptionInvoice] = useState({});
  const [subscriptonInfoModalOpen, setSubscriptonInfoModalOpen] =
    useState(false);
  const [phoneInterviewsDrawerOpen, setPhoneInterviewsDrawerOpen] =
    useState(false);
  const [templateType, setTemplateType] = useState('');
  const [documentType, setDocumentType] = useState(null);
  const [recordID, setRecordID] = useState(null);
  const [reviewData, setReviewData] = useState({});
  const [editReviewDrawer, setEditReviewDrawer] = useState(false);

  const getPensioner = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/pensioners/form/${id}?populate=subscription,document._id`
      });
      setPensioner(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await getPensioner();
    })();
  }, [getPensioner, refreshData]);

  const deletePensioner = async () => {
    try {
      await dispatchAPI('PATCH', {
        url: `/pensioners/${id}`,
        body: { status: 'ARCHIVED', is_archived: true }
      });
      await dispatchAPI('PATCH', {
        url: `/users/${pensioner.user._id}`,
        body: { is_archived: true, role: user.role }
      });
      navigate(-1);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    if (Object.keys(pensioner).length) {
      getSubscription({
        dispatchAPI,
        pensioner,
        setSubscription,
        setSubscriptionInvoice,
        setRecordID,
        message
      });
    }
  }, [pensioner]);

  const getSupervisions = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/supervisions?status=FINISHED&pensioners=${id}&populate=pensioners,main_informations.owner`
      });
      setSupervisions(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    getSupervisions();
  }, []);

  return (
    <>
      <PageHeaderCustom
        title={
          Object.keys(pensioner).length
            ? `${
                pensioner.civility
                  ? t(`pensioners.tags.${pensioner.civility}`)
                  : ''
              } ${pensioner.last_name} ${pensioner.first_name} n°${
                pensioner.reference
              }`
            : null
        }
        extra={
          <>
            <Button
              type="primary"
              onClick={() => {
                setIsEmailModalOpen(true);
                setTemplateType('SEND_MAIL');
                setDocumentType(null);
              }}
            >
              {t('buttons.send_mail')}
            </Button>
            <Dropdown
              overlay={
                <ExplanatoryDocumentMenu
                  pensioner={pensioner}
                  setIsEmailModalOpen={setIsEmailModalOpen}
                  setTemplateType={setTemplateType}
                  setDocumentType={setDocumentType}
                />
              }
            >
              <Button type="primary">
                {t('pensioners.show.buttons.explanatory_document')}
                <DownOutlined />
              </Button>
            </Dropdown>
            {pensioner && !pensioner.phone_interview ? (
              <Button
                type="primary"
                onClick={() => setPhoneInterviewsDrawerOpen(true)}
              >
                <PlusOutlined />
                {t('pensioners.form.buttons.tel_interview')}
              </Button>
            ) : null}
            <Link
              to={{
                pathname: editingLocked(profileLock, id)
                  ? ''
                  : `${routes.PENSIONERS}/edit/${id}`
              }}
            >
              <Button
                type="primary"
                onClick={() =>
                  editingLocked(profileLock, id) &&
                  notification.warning({
                    message: t('pensioners.profile_lock'),
                    duration: false
                  })
                }
              >
                {`${t('buttons.edit')} `}
                <EditOutlined />
              </Button>
            </Link>
            {!pensioner?.is_archived && (
              <Popconfirm
                title={t('datatable.column.action.archive.title')}
                okText={t('datatable.column.action.archive.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.archive.cancel')}
                onConfirm={deletePensioner}
                icon={<WarningOutlined />}
              >
                <Button type="link" danger>
                  {`${t('buttons.archive')} `}
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            )}
          </>
        }
      />
      <ContentCustom>
        <DetailsCards
          pensioner={pensioner}
          isLoading={isLoading}
          setRefreshData={setRefreshData}
          setIsLoading={setIsLoading}
          refreshData={refreshData}
          setPhoneInterviewsDrawerOpen={setPhoneInterviewsDrawerOpen}
          setSubscriptionDrawerOpen={setSubscriptionDrawerOpen}
          subscription={subscription}
          form={form}
          setQuestionnaireType={setQuestionnaireType}
          setSupervisionRating={setSupervisionRating}
          setReviewDrawerOpen={setReviewDrawerOpen}
          questionnaireType={questionnaireType}
          setShowReviewDrawer={setShowReviewDrawer}
          subscriptionInvoice={subscriptionInvoice}
          setSubscriptonInfoModalOpen={setSubscriptonInfoModalOpen}
          setIsEmailModalOpen={setIsEmailModalOpen}
          setTemplateType={setTemplateType}
          setDocumentType={setDocumentType}
          setReviewData={setReviewData}
          reviewData={reviewData}
          setEditReviewDrawer={setEditReviewDrawer}
        />
      </ContentCustom>
      {phoneInterviewsDrawerOpen ? (
        <PhoneInterviewDrawer
          t={t}
          setPhoneInterviewsDrawerOpen={setPhoneInterviewsDrawerOpen}
          phoneInterviewsDrawerOpen={phoneInterviewsDrawerOpen}
          setRefreshData={setRefreshData}
          refreshData={refreshData}
          form={form}
          pensioner={pensioner}
        />
      ) : null}
      {subscriptionDrawerOpen ? (
        <SubscriptionDrawer
          t={t}
          setSubscriptionDrawerOpen={setSubscriptionDrawerOpen}
          subscriptionDrawerOpen={subscriptionDrawerOpen}
          setRefreshData={setRefreshData}
          refreshData={refreshData}
          form={form}
          pensioner={pensioner}
          subscription={subscription}
          setSubscription={setSubscription}
          setSubscriptionInvoice={setSubscriptionInvoice}
          setRecordID={setRecordID}
        />
      ) : null}
      <ReviewDrawer
        setOpen={setReviewDrawerOpen}
        open={reviewDrawerOpen}
        t={t}
        questionnaireType={questionnaireType}
        customInstanceForm={form}
        supervision={supervisionRating}
        showReviewDrawer={showReviewDrawer}
        supervisions={supervisions}
        pensioner={pensioner}
        setSupervision={setSupervisionRating}
        setForceRefresh={setRefreshData}
        forceRefresh={refreshData}
        reviewData={reviewData}
        editReviewDrawer={editReviewDrawer}
      />
      {isEmailModalOpen && (
        <MailingModal
          isModalOpen={isEmailModalOpen}
          setIsModalOpen={setIsEmailModalOpen}
          recipients={[{ ...pensioner, customer_type: 'PENSIONER' }]}
          recipient={pensioner.user._id}
          customFormInstance={form}
          templateType={templateType}
          collection="pensioners"
          documentType={documentType}
          collectionData={subscriptionInvoice}
          recordID={recordID}
        />
      )}
      {subscriptonInfoModalOpen && (
        <SubscriptionInfoModal
          subscriptonInfoModalOpen={subscriptonInfoModalOpen}
          setSubscriptonInfoModalOpen={setSubscriptonInfoModalOpen}
          subscriptionInvoice={subscriptionInvoice}
          t={t}
        />
      )}
    </>
  );
};
