import { createFileFromJSON } from '../../../../utils/filesManagement/createFileFromJSON';

/**
 * Creates a configuration object for creating and updating resources.
 * @param {Object} options - The options for the configuration.
 * @param {Array} options.animalsFileList - The list of animal files.
 * @param {Function} options.setAnimalsFileList - The function to set the animal file list.
 * @param {Array} options.petsittingAddressFileList - The list of petsitting address files.
 * @param {Function} options.setPetsittingAddressFileList - The function to set the petsitting address file list.
 * @param {Function} options.dispatchAPI - The function to dispatch API requests.
 * @param {Function} options.message - The function to display messages.
 * @param {boolean} options.setInternetAccess - The flag to set internet access.
 * @param {string} options.formDataEnvironment - The environment data for the form data.
 * @param {Function} options.setFormDataEnvironment - The function to set the form data environment.
 * @param {Array} options.photoFileList - The list of photo files.
 * @param {Function} options.setCountry - The function to set the country.
 * @param {Function} options.setPhotoID - The function to set the photo ID.
 * @returns {Object} The configuration object.
 */
export const useConfig = ({
  animalsFileList,
  setAnimalsFileList,
  petsittingAddressFileList,
  setPetsittingAddressFileList,
  dispatchAPI,
  message,
  setInternetAccess,
  environmentType,
  setEnvironmentType,
  photoFileList,
  setCountry,
  setPhotoID
}) => ({
  onCreateResource: {
    setBody: (data) => {
      const formData = new FormData();
      if (photoFileList?.length) {
        photoFileList.forEach((file) => {
          formData.append('photo', file);
        });
      }

      if (Object.keys(animalsFileList).length) {
        const animalPhotoMetadata = {};

        Object.keys(animalsFileList).forEach((animalKey) => {
          animalsFileList[animalKey].forEach((file) => {
            formData.append('animal_photo', file.file);
            animalPhotoMetadata[file.file.name] = {
              animalKey,
              originalName: file.file.name
            };
          });
        });
        formData.append(
          'animal_photo_metadata',
          JSON.stringify(animalPhotoMetadata)
        );
      }

      if (petsittingAddressFileList.length) {
        petsittingAddressFileList.forEach((file) => {
          formData.append('dwelling_photos', file.file, file.file.name);
        });
      }

      formData.append(
        'values',
        JSON.stringify({
          ...data,
          petsitting_address: {
            ...data.petsitting_address,
            type_of_environment: environmentType
          }
        })
      );

      return formData;
    }
  },
  onUpdateResource: {
    setBody: (data) => {
      const formData = new FormData();

      if (photoFileList?.length) {
        photoFileList.forEach((file) => {
          formData.append('photo', file);
        });
      }

      if (Object.keys(animalsFileList).length) {
        const animalPhotoMetadata = {};

        Object.keys(animalsFileList).forEach((animalKey) => {
          animalsFileList[animalKey].forEach((file) => {
            formData.append('animal_photo', file.file, file.file.name);
            animalPhotoMetadata[file.file.name] = {
              animalKey,
              originalName: file.file.name
            };
          });
        });
        formData.append(
          'animal_photo_metadata',
          JSON.stringify(animalPhotoMetadata)
        );
      }

      if (petsittingAddressFileList.length) {
        petsittingAddressFileList.forEach((file) => {
          formData.append('dwelling_photos', file.file, file.file.name);
        });
      }

      if (data?.petsitting_address) {
        formData.append(
          'values',
          JSON.stringify({
            ...data,
            petsitting_address: {
              ...data.petsitting_address,
              type_of_environment: environmentType
            }
          })
        );
      } else {
        formData.append('values', JSON.stringify(data));
      }

      return formData;
    }
  },
  onGetResource: {
    setFields: async (data) => {
      if (data.photo) {
        setPhotoID(data.photo._id);
      }

      const { data: veterinarianData } = await dispatchAPI('GET', {
        url: `/veterinarians?owner=${data._id}`
      });

      const { data: groomerData } = await dispatchAPI('GET', {
        url: `/groomers?owner=${data._id}`
      });

      if (data?.petsitting_address?.dwelling_photos?.length) {
        try {
          const dwellingPhotosPromises = (
            data.petsitting_address.dwelling_photos || []
          ).map((photo) => createFileFromJSON(photo, dispatchAPI, message));

          const results = await Promise.all(dwellingPhotosPromises);
          setPetsittingAddressFileList(results);
        } catch (error) {
          message(message(error));
        }
      }

      if (data.animals.length) {
        try {
          const animalFileLists = await Promise.all(
            data.animals
              .filter((el) => !el.is_archived)
              .map(async (animal, animalIndex) => {
                const animalPhotosPromises = (animal.animal_photo || []).map(
                  (photo) => createFileFromJSON(photo, dispatchAPI, message)
                );

                const results = await Promise.all(animalPhotosPromises);

                return {
                  [animalIndex]: results.map((result) => ({
                    ...result,
                    animalIndex
                  }))
                };
              })
          );

          setAnimalsFileList((prevList) => {
            let updatedList = { ...prevList };
            animalFileLists.forEach((animalFileList) => {
              updatedList = { ...updatedList, ...animalFileList };
            });
            return updatedList;
          });
        } catch (error) {
          message(error);
        }
      }

      if (data?.petsitting_address?.type_of_environment) {
        setEnvironmentType(data.petsitting_address.type_of_environment);
      }

      setInternetAccess(data.internet_access);

      setCountry(data?.address?.country);

      const newData = {
        ...data,
        animals: data.animals.filter((el) => !el.is_archived),
        veterinarian: veterinarianData[0],
        groomer: groomerData[0]
      };

      return newData;
    }
  }
});
