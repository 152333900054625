import React, { useState, useEffect, useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Steps, message as Message, Form } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { ContentCustom } from '../../components';
import { MainInformationsForm } from './components/forms/MainInformations';
import { SupervisionAddressForm } from './components/forms/SupervisionAddress';
import { SummaryForm } from './components/forms/Summary';
import { useConfig } from './utils/CreateUpdateConfig';
import { CreateUpdateContainerSupervision } from './components/CreateUpdateContainerSupervision';
import { addDuration } from './utils/addDuration';
// import { changeForm } from './utils/changeForm';
import { handlePrevious } from './utils/handlePrevious';
import { getOwner } from './utils/getOwner';
import { useUnlockEditProfile } from '../../utils/useUnlockEditProfile';
import { useHandleProfileLock } from '../../utils/useHandleProfileLock';
import { useSupervisionContext } from '../../contexts/SupervisionContext/SupervisionContext';

export const CreateUpdateSupervision = ({ purpose }) => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const location = useLocation();
  const { unlockEditProfile } = useUnlockEditProfile(id, 'supervisions');
  const { handleProfileLock } = useHandleProfileLock(id, 'supervisions');
  const {
    owner,
    setOwner,
    isFieldsLoading,
    supervisionsPrices,
    setPetsittingAddressFileList,
    setTypeOfDwelling,
    setEnvironmentType,
    supervisionDispatch,
    setSelectedAnouncePhotos,
    selectedAnouncePhotos
  } = useSupervisionContext();

  const [current, setCurrent] = useState(0);

  const animalsRef = useRef([]);
  const startDateRef = useRef({});
  const endDateRef = useRef({});

  const [duration, setDuration] = useState([]);
  const [differenceDays, setDifferenceDays] = useState(0);
  const [animalsFileList, setAnimalsFileList] = useState([]);
  const [reduction, setReduction] = useState(0);
  const [country, setCountry] = useState('');

  const searchParams = new URLSearchParams(location.search);
  const OwnerId = searchParams.get('id');

  useEffect(() => {
    if (purpose === 'edit') {
      (async () => {
        await handleProfileLock();
      })();

      window.addEventListener('beforeunload', async () => {
        await unlockEditProfile();
      });
    }

    return async () => {
      if (purpose === 'edit') {
        // clean up the event handler when the component unmounts
        window.removeEventListener('beforeunload', async () => {
          await unlockEditProfile();
        });
        // call the unlock func when the component unmounts
        await unlockEditProfile();
      }
    };
  }, []);

  useEffect(() => {
    if (purpose === 'create' && OwnerId) {
      getOwner({
        dispatchAPI,
        setOwner,
        message,
        OwnerId,
        setPetsittingAddressFileList,
        setEnvironmentType
      });
    }
  }, [purpose]);

  useEffect(() => {
    if (purpose === 'create') {
      form.setFieldsValue({ petsitting_address: owner?.petsitting_address });
    }
  }, [owner]);

  useEffect(
    () => () => {
      if (purpose === 'create') supervisionDispatch({ type: 'RESET' });
      form.resetFields();
    },
    []
  );

  useEffect(() => {
    if (animalsRef.current.length) {
      animalsRef.current.forEach((animal) => {
        animal.animal_photo?.forEach((photo) => {
          setAnimalsFileList((previousResult) => [...previousResult, photo]);
        });
      });
    }
  }, [animalsRef.current]);

  const config = useConfig({
    setPetsittingAddressFileList,
    dispatchAPI,
    message,
    setSelectedAnouncePhotos,
    setSupervisionAddressData: (payload) =>
      supervisionDispatch({ type: 'SET_SUPERVISION_ADDRESS_DATA', payload }),
    startDateRef,
    endDateRef,
    animals: animalsRef,
    setReduction,
    setOwner,
    setEnvironmentType,
    setCountry,
    setAnimalsFileList,
    setTypeOfDwelling,
    form
  });

  useEffect(() => {
    addDuration({
      startDate: startDateRef.current,
      endDate: endDateRef.current,
      setDifferenceDays,
      supervisionsPrices,
      form,
      setDuration
    });
  }, [startDateRef.current, endDateRef.current]);

  const handleNextStep = async (action) => {
    try {
      const values = await form.validateFields();

      supervisionDispatch({ type: `SET_${action}`, payload: values });

      if (current <= 1) setCurrent((prevCurrent) => prevCurrent + 1);

      return null;
    } catch (error) {
      return Message.error(t('supervisions.messages.required_fields'));
    }
  };

  const forms = [
    <MainInformationsForm
      handleNextStep={handleNextStep}
      animalsRef={animalsRef}
      form={form}
      startDate={startDateRef}
      endDate={endDateRef}
    />,
    <SupervisionAddressForm
      handleNextStep={handleNextStep}
      handlePrevious={() => handlePrevious({ setCurrent, current })}
      form={form}
      country={country}
      setCountry={setCountry}
    />,
    <SummaryForm
      handleNextStep={handleNextStep}
      handlePrevious={() => handlePrevious({ setCurrent, current })}
      form={form}
      duration={duration}
      setDuration={setDuration}
      differenceDays={differenceDays}
      animalsFileList={animalsFileList}
      selectedAnouncePhotos={selectedAnouncePhotos}
      setSelectedAnouncePhotos={setSelectedAnouncePhotos}
      reduction={reduction}
      setReduction={setReduction}
      purpose={purpose}
    />
  ];
  const steps = [
    {
      title: t('supervisions.form.main_informations.title'),
      icon: <CheckCircleOutlined />
    },
    {
      title: t('supervisions.form.supervision_address'),
      icon: <CheckCircleOutlined />
    },
    {
      title: t('supervisions.form.summary.title'),
      icon: <CheckCircleOutlined />
    }
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title
  }));

  return (
    <ContentCustom>
      <>
        <Steps
          current={current}
          items={items}
          // onChange={(e) =>
          //   changeForm({
          //     nextStep: e,
          //     form,
          //     setCurrent,
          //     Message,
          //     t,
          //     current,
          //     setInformationData,
          //     setSupervisionAddressData,
          //     setSummaryData
          //   })
          // }
          style={{ marginBottom: 24 }}
        />
        <CreateUpdateContainerSupervision
          loadingFields={isFieldsLoading}
          purpose={purpose}
          customFormInstance={form}
          baseUrl="supervisions/form"
          resource="supervisions"
          config={config}
          formExtra={forms[current]}
        />
      </>
    </ContentCustom>
  );
};

CreateUpdateSupervision.propTypes = {
  purpose: PropTypes.string.isRequired
};
