import React from 'react';
import { Button, Col, List, Row, Skeleton } from 'antd';
import {
  CheckCircleOutlined,
  DownloadOutlined,
  EyeOutlined
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { generateDocumentsOld } from '../../mail-sender/utils/generateDocumentsOld';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useViewDocument } from '../../../utils/filesManagement/viewDocument';

/**
 * React component for displaying a list of documents.
 * @component
 * @param {Object} props - The component properties.
 * @param {boolean} props.isLoading - Indicates whether documents are in a loading state.
 * @param {Array} props.documents - Array of document objects.
 * @param {boolean} [props.withTitle=false] - Flag to display a title above the document list.
 * @returns {JSX.Element} - React component.
 * @example
 * // Example usage of the ListContractDocuments component:
 * <ListContractDocuments
 *   isLoading={loading}
 *   documents={documentList}
 * />
 */
export const ListContractDocuments = ({
  isLoading,
  setIsLoading,
  documents,
  filterID
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { viewDocument } = useViewDocument(setIsLoading);

  return (
    <Row>
      <Col span={16}>
        <List
          className="demo-loadmore-list"
          loading={isLoading}
          itemLayout="horizontal"
          dataSource={documents}
          renderItem={(item) => (
            <List.Item
              actions={[
                <Button
                  type="link"
                  onClick={async () => {
                    await viewDocument(item?._id);
                  }}
                >
                  <EyeOutlined />
                </Button>,
                <Button
                  type="link"
                  onClick={() =>
                    generateDocumentsOld(
                      dispatchAPI,
                      message,
                      item.document_type,
                      item.supervision_id,
                      filterID,
                      t
                    )
                  }
                >
                  <DownloadOutlined />
                </Button>
              ]}
            >
              <Skeleton avatar title={false} loading={item.loading} active>
                <List.Item.Meta
                  avatar={<CheckCircleOutlined />}
                  description={t(
                    'dashboard.show.document.description_contract',
                    {
                      reference: item.supervision_reference
                    }
                  )}
                />
              </Skeleton>
            </List.Item>
          )}
        />
      </Col>
    </Row>
  );
};

ListContractDocuments.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      metadata: PropTypes.shape({
        originalName: PropTypes.string.isRequired
      })
    })
  ),
  filterID: PropTypes.string
};

ListContractDocuments.defaultProps = {
  documents: [],
  filterID: null
};
