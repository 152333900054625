import { useState, useEffect } from 'react';
import { Card, Button, Form, Space } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ListReservations } from '../dataTables/ListReservations';
import { CreateUpdateReservation } from '../../../../reservation/CreateUpdateReservation';
import { generateFilterAndRecordID } from '../../../utils/generateFilterAndRecordID';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { generateDocumentsOld } from '../../../../mail-sender/utils/generateDocumentsOld';

/**
 * Renders a card component that displays reservations for a supervision.
 * @component
 *
 * @param {Object} props - The component props.
 * @param {string} props.id - The ID of the supervision.
 * @param {function} props.refreshData - A function to refresh the data.
 * @param {function} props.setRefreshData - A function to set the refresh data.
 * @param {Object} props.supervision - The supervision object.
 * @returns {JSX.Element} The reservations card component.
 */
export const ReservationsCard = ({
  id,
  refreshData,
  setRefreshData,
  supervision,
  setIsEmailModalOpen,
  setTemplateType,
  setFilterID,
  setRecordID,
  documentType,
  setDocumentType,
  setRecipients
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isCreateUpdateModalOpen, setIsCreateUpdateModalOpen] = useState(false);
  const option = {
    ...supervision?.main_informations?.owner,
    type: 'owner',
    customer_type: 'OWNER',
    collection: 'Supervision',
    user_collection: 'owners'
  };

  const handleSendReservationList = () => {
    if (option.email) {
      setRecipients([option]);
      setTemplateType('SEND_RESERVATION_REQUESTS_LIST');
      setDocumentType('RESERVATION_REQUESTS_LIST');
      generateFilterAndRecordID({
        dispatchAPI,
        documentType,
        id,
        option,
        supervision,
        setFilterID,
        setRecordID
      });
      setIsEmailModalOpen(true);
    } else {
      generateDocumentsOld(
        'RESERVATION_REQUESTS_LIST',
        supervision?._id,
        undefined
      );
    }
  };

  useEffect(() => {
    form.setFieldValue(['supervision'], supervision._id);
  }, []);
  return (
    <>
      <Card
        title={t('supervisions.show.reservation.title')}
        extra={
          <Space>
            <Button onClick={handleSendReservationList}>
              {option?.email
                ? t('supervisions.show.buttons.send_reservation_list')
                : t('supervisions.show.buttons.generate_reservation_list')}
            </Button>
            <Button onClick={() => setIsCreateUpdateModalOpen(true)}>
              {t('supervisions.show.reservation.create')}
            </Button>
          </Space>
        }
      >
        <ListReservations
          supervisionId={id}
          refreshData={refreshData}
          setRefreshData={setRefreshData}
          supervision={supervision}
        />
      </Card>
      <CreateUpdateReservation
        isCreateUpdateModalOpen={isCreateUpdateModalOpen}
        setIsCreateUpdateModalOpen={setIsCreateUpdateModalOpen}
        purpose="create"
        setRefreshData={setRefreshData}
        refreshData={refreshData}
        customId={null}
        form={form}
        disabledFields={['supervision']}
      />
    </>
  );
};

ReservationsCard.propTypes = {
  supervision: PropTypes.shape({
    _id: PropTypes.string,
    main_informations: PropTypes.shape({
      owner: PropTypes.shape({
        email: PropTypes.string
      })
    })
  }).isRequired,
  id: PropTypes.string.isRequired,
  refreshData: PropTypes.bool,
  setRefreshData: PropTypes.func,
  setIsEmailModalOpen: PropTypes.func.isRequired,
  setTemplateType: PropTypes.func.isRequired,
  setFilterID: PropTypes.func.isRequired,
  setRecordID: PropTypes.func.isRequired,
  documentType: PropTypes.string.isRequired,
  setDocumentType: PropTypes.func.isRequired,
  setRecipients: PropTypes.func.isRequired
};

ReservationsCard.defaultProps = {
  refreshData: false,
  setRefreshData: () => {}
};
