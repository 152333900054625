import { createFilter } from '../../../utils/columnFilters/createFilter';

/**
 * Creates a filter for searching by postal code within the address field.
 * @function
 *
 * @param {string} searchInput - The input string to search for within the postal code.
 * @param {function} t - Translation function for placeholder text.
 * @returns {object} - The filter configuration object.
 */
export const postalCodeFilter = (searchInput, t) =>
  createFilter({
    dataIndex: 'address',
    placeHolder: 'owners.place_holders.search_by_postal_code',
    filterFunction: (value, record, dataIndex) =>
      record?.[dataIndex]?.postal_code.toString().toLowerCase().includes(value),
    searchInput,
    t
  });
