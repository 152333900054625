import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Steps, Form, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { ContentCustom, PageHeaderCustom } from '../../components';
import { DetailsCards } from './ShowSupervision/detailsCards/DetailsCards';
import { AnounceModal } from './ShowSupervision/detailsCards/modals/AnounceModal';
import { CancelSupervisionModal } from './ShowSupervision/detailsCards/modals/CancelSupervisionModal';
import { InterruptSupervisionModal } from './ShowSupervision/detailsCards/modals/InterrupSupervisionModal';
import { useItems } from './ShowSupervision/utils/stepItems';
import { MailingModal } from '../../components/Mailing/MailingModal';
import { useSupervisionContext } from '../../contexts/SupervisionContext/SupervisionContext';
import { ActionsButtons } from './ActionsButtons';

/**
 * A component that displays the details of a pet supervision.
 * @component
 *
 * @returns {JSX.Element} The rendered component.
 */
export const ShowSupervision = () => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const { t } = useTranslation();

  const {
    state,
    getSupervision,
    getAnounce,
    isLoading,
    setIsLoading,
    selectedAnouncePhotos,
    setSelectedAnouncePhotos,
    setCurrent,
    setStatus,
    setAnounceModalVisible,
    setCancelSupervisionModalVisible,
    setInterruptSupervisionModalVisible,
    setIsEmailModalOpen,
    setCustomerInvoices,
    recipients,
    templateType,
    documentType,
    filterID,
    recordID
  } = useSupervisionContext();
  const {
    supervision,
    anounce,
    status,
    current,
    anounceModalVisible,
    cancelSupervisionModalVisible,
    interruptSupervisionModalVisible,
    isEmailModalOpen
  } = state;

  const { items, arrStatus } = useItems({ t, status });
  const [refreshData, setRefreshData] = useState(false);

  useEffect(() => {
    if (supervision) {
      setStatus(supervision.status);
      setCurrent(arrStatus.indexOf(supervision.status));
    }
  }, [supervision]);

  useEffect(() => {
    (async () => {
      await Promise.all([getAnounce(id), getSupervision(id)]);
    })();
  }, [refreshData]);

  return (
    <>
      {isLoading ? (
        <Skeleton.Button loading={isLoading} active block size="large" />
      ) : (
        <PageHeaderCustom
          title={t(`supervisions.show.title`, {
            reference: supervision?.reference
          })}
          customBreadcrumbs="supervision"
          supervision={supervision}
          extra={
            <ActionsButtons
              supervisionId={id}
              status={status}
              setRefreshData={setRefreshData}
              setAnounceModalVisible={setAnounceModalVisible}
              setCancelSupervisionModalVisible={
                setCancelSupervisionModalVisible
              }
              setIsEmailModalOpen={setIsEmailModalOpen}
              setInterruptSupervisionModalVisible={
                setInterruptSupervisionModalVisible
              }
            />
          }
        />
      )}
      <ContentCustom>
        <Steps items={items} size="small" current={current} />
        <DetailsCards
          supervisionBis={supervision}
          anounce={anounce}
          address={supervision?.address}
          isLoading={isLoading}
          setRefreshData={setRefreshData}
          refreshData={refreshData}
          setIsLoading={setIsLoading}
          id={id}
          setAnounceModalVisible={setAnounceModalVisible}
          setCustomerInvoices={setCustomerInvoices}
          setIsEmailModalOpen={setIsEmailModalOpen}
        />
      </ContentCustom>
      {anounceModalVisible && (
        <AnounceModal
          t={t}
          anounceModalVisible={anounceModalVisible}
          setAnounceModalVisible={setAnounceModalVisible}
          supervision={supervision}
          selectedAnouncePhotos={selectedAnouncePhotos}
          setSelectedAnouncePhotos={setSelectedAnouncePhotos}
          anounce={anounce}
          setRefreshData={setRefreshData}
          refreshData={refreshData}
        />
      )}
      {cancelSupervisionModalVisible && (
        <CancelSupervisionModal
          cancelSupervisionModalVisible={cancelSupervisionModalVisible}
          setCancelSupervisionModalVisible={setCancelSupervisionModalVisible}
          t={t}
          setRefreshData={setRefreshData}
          refreshData={refreshData}
        />
      )}
      {interruptSupervisionModalVisible && (
        <InterruptSupervisionModal
          interruptSupervisionModalVisible={interruptSupervisionModalVisible}
          setInterruptSupervisionModalVisible={
            setInterruptSupervisionModalVisible
          }
          t={t}
          setRefreshData={setRefreshData}
          refreshData={refreshData}
          supervision={supervision}
        />
      )}
      {isEmailModalOpen && (
        <MailingModal
          isModalOpen={isEmailModalOpen}
          setIsModalOpen={setIsEmailModalOpen}
          recipients={recipients}
          recipient={recipients[0]?.user._id}
          customFormInstance={form}
          collection={recipients[0]?.user_collection}
          templateType={templateType}
          collectionData={supervision}
          documentType={documentType}
          recordID={recordID}
          filterID={filterID}
          setRefreshData={setRefreshData}
        />
      )}
    </>
  );
};
