/**
 * Generates a configuration object for a rich text editor with additional buttons.
 *
 * @hook
 *
 * @param {Array} extraButtons - An array of extra button configurations to include in the editor.
 * @returns {Object} Configuration object for the rich text editor.
 */
export const createConfig = (extraButtons) => ({
  uploader: {
    insertImageAsBase64URI: true // Use this if you want to store images as base64 URIs
  },
  buttons: [
    'source',
    '|',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    '|',
    'superscript',
    'subscript',
    '|',
    'ul',
    'ol',
    '|',
    'outdent',
    'indent',
    '|',
    'font',
    'fontsize',
    'brush',
    'paragraph',
    '|',
    'image',
    'table',
    'link',
    '|',
    'align',
    'undo',
    'redo',
    '|',
    'hr',
    'eraser',
    'copyformat',
    '|',
    'fullsize',
    'print',
    ...extraButtons
  ],
  createAttributes: {
    table: {
      style:
        'width: 100%; border-collapse: collapse; page-break-before: auto; page-break-after: auto;'
    },
    td: {
      style: 'page-break-inside: avoid;'
    },
    tr: {
      style: 'page-break-inside: avoid;'
    },
    th: {
      style: 'page-break-inside: avoid;'
    }
  }
});
