import { createFileFromJSON } from '../../../../utils/filesManagement/createFileFromJSON';

/**
 * Configuration object for handling petsitting address resources.
 * @function
 *
 * @param {Array} petsittingAddressFileList - List of files related to the petsitting address.
 * @param {Function} setPetsittingAddressFileList - Function to update the list of petsitting address files.
 * @param {string} environmentType - The type of environment for the petsitting address.
 * @param {Function} setEnvironmentType - Function to update the environment type.
 * @param {Function} dispatchAPI - Function to dispatch API calls.
 * @param {Function} message - Function to display messages.
 * @param {Function} setCountry - Function to set the country of the address.
 * @param {Function} setTypeOfDwelling - Function to set the type of dwelling.
 * @returns {Object} Configuration object with methods for creating, updating, and getting resources.
 */
export const useConfig = (
  petsittingAddressFileList,
  setPetsittingAddressFileList,
  environmentType,
  setEnvironmentType,
  dispatchAPI,
  message,
  setCountry,
  setTypeOfDwelling
) => ({
  onCreateResource: {
    setBody: (data) => {
      const formData = new FormData();

      if (petsittingAddressFileList.length) {
        petsittingAddressFileList.forEach((file) => {
          formData.append('dwelling_photos', file.file, file.file.name);
        });
      }

      formData.append(
        'values',
        JSON.stringify({ ...data, type_of_environment: environmentType })
      );

      return formData;
    }
  },
  onUpdateResource: {
    setBody: (data) => {
      const formData = new FormData();

      if (petsittingAddressFileList.length) {
        petsittingAddressFileList.forEach((file) => {
          formData.append('dwelling_photos', file.file, file.file.name);
        });
      }

      formData.append(
        'values',
        JSON.stringify({
          ...data,
          petsitting_address: {
            ...data.petsitting_address,
            type_of_environment: environmentType
          }
        })
      );

      return formData;
    }
  },
  onGetResource: {
    setFields: async (data) => {
      if (data?.dwelling_photos && data?.dwelling_photos?.length) {
        try {
          const dwellingPhotosPromises = (data.dwelling_photos || []).map(
            (photo) => createFileFromJSON(photo, dispatchAPI, message)
          );

          const results = await Promise.all(dwellingPhotosPromises);
          setPetsittingAddressFileList(results ?? []);
        } catch (error) {
          message(message(error));
        }
      }

      if (data?.type_of_environment) {
        setEnvironmentType(data.type_of_environment);
      }

      if (data?.type_of_dwelling) setTypeOfDwelling(data?.type_of_dwelling);

      setCountry(data?.address?.country);

      return { petsitting_address: data };
    }
  }
});
