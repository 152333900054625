/**
 * Retrieves the owner data from the server.
 *
 * @param {Object} options - The options for retrieving the owner data.
 * @param {Function} options.dispatchAPI - The function for dispatching API requests.
 * @param {Function} options.setOwner - The function for setting the owner data.
 * @param {Function} options.message - The function for displaying error messages.
 * @param {string} options.OwnerId - The ID of the owner to retrieve.
 * @param {Function} options.setPetsittingAddressFileList - The function for setting the list of petsitting address files.
 * @returns {Promise<void>} - A promise that resolves when the owner data is retrieved successfully.
 */
export const getOwner = async ({
  dispatchAPI,
  setOwner,
  message,
  OwnerId,
  setPetsittingAddressFileList,
  setEnvironmentType
}) => {
  try {
    const { data } = await dispatchAPI('GET', {
      url: `/owners/${OwnerId}?populate=animals,petsitting_address&is_archived=false`
    });
    setOwner(data);
    setPetsittingAddressFileList(
      data.petsitting_address?.dwelling_photos || []
    );
    setEnvironmentType(data.petsitting_address?.type_of_environment);
  } catch (e) {
    message(e);
  }
};
