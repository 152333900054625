import React, { useState } from 'react';
import { Flex, DatePicker } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

/**
 * ExtraFilters component renders a date picker for filtering data by date.
 * @component
 *
 * @param {Object} props - The component props.
 * @param {Function} props.setDateFilter - Function to set the date filter.
 * @param {Function} props.setDateType - Function to set the date type.
 * @returns {JSX.Element} The ExtraFilters component.
 */
export const ExtraFilters = ({ setDateFilter, setDateType }) => {
  const { t } = useTranslation();
  const [dates, setDates] = useState({ start_date: null, end_date: null });

  const handleDateChange = (type) => (date) => {
    setDates((prevDates) => ({
      ...prevDates,
      start_date: type === 'start_date' ? date : null,
      end_date: type === 'end_date' ? date : null
    }));
    setDateType(type);
    setDateFilter(date);
  };

  const datePickers = [
    {
      type: 'start_date',
      placeholder: t('supervisions.place_holders.start_date')
    },
    {
      type: 'end_date',
      placeholder: t('supervisions.place_holders.end_date')
    }
  ];

  return (
    <Flex gap="middle" align="center">
      {datePickers.map(({ type, placeholder }) => (
        <DatePicker
          key={type}
          placeholder={placeholder}
          style={{ width: 200 }}
          format="DD/MM/YYYY"
          value={dates[type]}
          onChange={handleDateChange(type)}
        />
      ))}
    </Flex>
  );
};

ExtraFilters.propTypes = {
  setDateFilter: PropTypes.func,
  setDateType: PropTypes.func
};

ExtraFilters.defaultProps = {
  setDateFilter: () => {},
  setDateType: () => {}
};
