import { Image, Spin, Flex } from 'antd';
import PropTypes from 'prop-types';
import { DeleteOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../../contexts/AuthContext';

/**
 * ImagePreviewList component displays a list of image previews with the ability to remove images.
 * @component
 *
 * @param {Object} props - The component props.
 * @param {Object} props.file - The file object to be displayed.
 * @param {Function} props.setFileList - Function to update the file list state.
 * @param {Array} props.fileList - The current list of files.
 * @returns {JSX.Element} The rendered component.
 */
export const ImagePreviewList = ({ file, setFileList, fileList }) => {
  const { token } = useAuthContext();

  const onRemove = (fileToRemove) => {
    fileList.forEach((item) => {
      if (typeof item === 'string') {
        if (item === fileToRemove) {
          const index = fileList.indexOf(item);
          const newFileList = fileList.slice();
          newFileList.splice(index, 1);
          setFileList(newFileList);
        }
      }
      if (typeof item === 'object') {
        if (item.uid === fileToRemove.uid) {
          const index = fileList.indexOf(item);
          const newFileList = fileList.slice();
          newFileList.splice(index, 1);
          setFileList(newFileList);
        }
      }
    });
  };

  if (file.url) {
    return (
      <Flex>
        <Image src={file.url} placeholder={<Spin loading size="large" />} />
        <DeleteOutlined
          onClick={() => onRemove(file)}
          style={{ color: 'red' }}
        />
      </Flex>
    );
  }
  return (
    <Flex>
      <Image
        src={`${process.env.REACT_APP_API_URL}/anounces/get-url/${file}/${token}`}
        placeholder={<Spin loading size="large" />}
      />
      <DeleteOutlined onClick={() => onRemove(file)} style={{ color: 'red' }} />
    </Flex>
  );
};

ImagePreviewList.propTypes = {
  file: PropTypes.shape({
    url: PropTypes.string
  }),
  setFileList: PropTypes.func,
  fileList: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])
  )
};

ImagePreviewList.defaultProps = {
  file: {
    url: ''
  },
  setFileList: () => {},
  fileList: []
};
