import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { EyeOutlined } from '@ant-design/icons';

/**
 * Returns an array of columns configuration for the supervision prices table.
 * @hook
 * @returns {Array} The array of columns configuration.
 */
export const useColumns = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { pathname } = location;

  return [
    {
      title: t('supervisionprices.form.duration'),
      key: 'duration',
      dataIndex: 'duration',
      render: (duration) => {
        const extra_week = duration?.extra_week;

        return extra_week
          ? duration.extra_week
          : `de ${duration.start_day} à ${duration.end_day} jours`;
      },
      sorter: true
    },
    {
      title: t('supervisionprices.form.rate.tva'),
      key: 'rate.tva',
      dataIndex: ['rate', 'tva'],
      render: (tva) => `${tva} %`,
      sorter: true
    },
    {
      title: t('supervisionprices.form.rate.total_ttc'),
      key: 'rate.total_ttc',
      dataIndex: ['rate', 'total_ttc'],
      render: (total_ttc) => `${total_ttc} €`,
      sorter: true
    },
    {
      title: t('supervisionprices.form.rate.advance_payment'),
      key: 'rate.advance_payment',
      dataIndex: ['rate', 'advance_payment'],
      render: (advance_payment) => `${advance_payment} €`,
      sorter: true
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      width: 120,
      render: (record) => (
        <Link
          to={{
            pathname: `${pathname}/show/${record?._id}`
          }}
        >
          <EyeOutlined style={{ fontSize: 18 }} />
        </Link>
      )
    }
  ];
};
