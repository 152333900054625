/**
 * Fetches invoices for a given user and updates the state with the fetched invoices.
 * @function
 * @async
 *
 * @param {Object} params - The parameters for the function.
 * @param {Function} params.dispatchAPI - The function to dispatch API calls.
 * @param {Object} params.user - The user object containing user details.
 * @param {Function} params.setInvoices - The function to update the state with the fetched invoices.
 * @param {Function} params.message - The function to display error messages.
 * @returns {Promise<void>} - A promise that resolves when the invoices are fetched and state is updated.
 */
export const getInvoices = async ({
  dispatchAPI,
  user,
  setInvoices,
  message
}) => {
  try {
    const { data } = await dispatchAPI('GET', {
      url: `customerinvoices?customer=${user._id}&populate=supervision`
    });
    const updatedInvoices = [];

    if (data.length) {
      data.map((invoice) =>
        updatedInvoices.push({
          ...invoice,
          document_type:
            invoice.type === 'SUBSCRIPTION'
              ? 'SUBSCRIPTION-INVOICE'
              : 'OWNER-INVOICE'
        })
      );
    }
    setInvoices(updatedInvoices);
  } catch (e) {
    message(e);
  }
};
