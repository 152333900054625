import {
  Form,
  Input,
  Select,
  Tag,
  DatePicker,
  Upload,
  Button,
  Row,
  Col,
  notification
} from 'antd';
import {
  UploadOutlined,
  PlusOutlined,
  CloseOutlined
} from '@ant-design/icons/lib';
import { useTranslation } from 'react-i18next';
import { secondaryProfileInformations } from './secondaryProfileInformationFields';
import { compressFile } from '../../../utils/filesManagement/compressFile';
import { fileExtensionManage } from '../../../utils/filesManagement/fileExtensionManage';

const { Option } = Select;
const { Dragger } = Upload;

/**
 * Personal Informations Fields
 * @hook
 * @typedef {Object} PersonalInformationsFields
 * @property {function} setFilesList - Function to set the files list.
 * @property {Array} filesList - List of files.
 * @property {Object} enums - Object containing enums.
 * @property {boolean} isFieldsLoading - Flag indicating if fields are loading.
 * @property {function} setShowSecondProfile - Function to set the showSecondProfile flag.
 * @property {boolean} showSecondProfile - Flag indicating if the second profile is shown.
 * @property {function} setRemovedFiles - Function to set the removed files.
 *
 * @returns {Object} - Object containing the personal informations fields.
 */
export const personalInformations = ({
  setFilesList,
  filesList,
  enums,
  isFieldsLoading,
  setShowSecondProfile,
  showSecondProfile,
  setRemovedFiles
}) => {
  const { t } = useTranslation();

  const filteredEnums = enums?.civility?.filter(
    (civility) => !civility.includes('&')
  );

  const { secondaryProfileInformationsFields } = secondaryProfileInformations({
    setFilesList,
    filesList,
    filteredEnums,
    isFieldsLoading,
    setRemovedFiles
  });

  const draggerProps = (name) => ({
    onRemove: () => {
      const updatedFileList = { ...filesList };
      setRemovedFiles((prevRemovedFiles) => [
        ...prevRemovedFiles,
        updatedFileList[name][0]?.id
      ]);
      if (name === 'identification_photo') {
        updatedFileList[name] = [];
      } else {
        delete updatedFileList[name];
      }
      setFilesList(updatedFileList);
    },
    beforeUpload: async (file) => {
      const newFile = file;
      const fileExtension = file.name.split('.').pop();

      if (name === 'identification_photo' && fileExtension !== 'pdf') {
        const compressedFile = await compressFile(file);
        const reader = new FileReader();
        reader.onload = (e) => {
          newFile.url = e.target.result;
        };
        reader.readAsDataURL(file);
        setFilesList((prevFileList) => ({
          ...prevFileList,
          [name]: [compressedFile]
        }));
        return false;
      }
      if (
        name !== 'identification_photo' &&
        fileExtensionManage(fileExtension)
      ) {
        setFilesList((prevFileList) => ({
          ...prevFileList,
          [name]: [newFile]
        }));

        return false;
      }

      notification.warning({
        message: t('messages.file_extension_warning', {
          extensions: `png, jpg, jpeg, ${
            name !== 'identification_photo' ? 'pdf' : ''
          }`
        }),
        duration: 5
      });
      return true;
    },
    fileList: filesList[name]
  });

  const toggleSecondProfile = () => {
    setShowSecondProfile(!showSecondProfile);
  };

  const personnalInformationsFields = [
    {
      name: ['civility'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {(filteredEnums || []).map((civility) => (
            <Option key={civility} value={civility}>
              <Tag>{t(`pensioners.tags.${civility}`)}</Tag>
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['last_name'],
      rules: [{ required: true }]
    },
    {
      name: ['first_name'],
      rules: [{ required: true }]
    },
    {
      name: ['birth_date'],
      input: <DatePicker />
    },
    {
      name: ['profession']
    },
    {
      name: ['principal_language']
    },
    {
      name: ['cell_phone_number'],
      label: 'cell_phone_number.number',
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['cell_phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select style={{ width: 70 }}>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={['cell_phone_number', 'number']}>
            <Input type="number" style={{ width: 'calc(100% - 70px)' }} />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      label: 'other_languages.label',
      input: (
        <Form.List name={['other_languages']}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ name, key }) => (
                <Row justify="space-between" key={key}>
                  <Col>
                    <Button onClick={() => remove(name)}>
                      <CloseOutlined />
                    </Button>
                  </Col>

                  <Form.Item noStyle name={[name]}>
                    <Input />
                  </Form.Item>
                </Row>
              ))}
              <Button
                type="dashed"
                onClick={() => {
                  add();
                }}
                icon={<PlusOutlined />}
              >
                {t('pensioners.form.other_languages.add_button')}
              </Button>
            </>
          )}
        </Form.List>
      )
    },
    {
      label: 'criminal_record_extract',
      input: (
        <Dragger {...draggerProps('criminal_record_extract')}>
          <p className="ant-upload-drag-icon">
            <UploadOutlined style={{ color: 'var(--textColor)' }} />
          </p>
          <p className="ant-upload-text">{t('files.create.action')}</p>
        </Dragger>
      )
    },
    {
      label: 'identification_photocopy',
      input: (
        <Dragger {...draggerProps('identification_photocopy')}>
          <p className="ant-upload-drag-icon">
            <UploadOutlined style={{ color: 'var(--textColor)' }} />
          </p>
          <p className="ant-upload-text">{t('files.create.action')}</p>
        </Dragger>
      )
    },
    {
      label: 'identification_photo',
      input: (
        <Dragger {...draggerProps('identification_photo')}>
          <p className="ant-upload-drag-icon">
            <UploadOutlined style={{ color: 'var(--textColor)' }} />
          </p>
          <p className="ant-upload-text">{t('files.create.action')}</p>
        </Dragger>
      )
    },
    {
      noLabel: true,
      hidden: !showSecondProfile,
      name: [''],
      input: (
        <Button onClick={toggleSecondProfile}>
          {t('pensioners.form.buttons.cancel')}
        </Button>
      )
    },
    ...(showSecondProfile ? secondaryProfileInformationsFields : []),
    {
      noLabel: true,
      hidden: showSecondProfile,
      name: [''],
      input: (
        <Button
          type="dashed"
          onClick={toggleSecondProfile}
          block
          style={{ transform: 'translateX(100%)' }}
        >
          {t('pensioners.form.buttons.add_profile')}
        </Button>
      )
    }
  ];

  return { personnalInformationsFields };
};
