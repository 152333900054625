import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { ListResource } from '../../components';
import { useColumns } from './columns';
import { CreateUpdateReservation } from './CreateUpdateReservation';
import ReservationModal from './ReservationModal';
import { useErrorMessage } from '../../utils/errorMessage';
import { useAuthContext } from '../../contexts/AuthContext';
import { MailingModal } from '../../components/Mailing/MailingModal';

/**
 * Composant de liste des réservations.
 * @component
 * @returns {JSX.Element} Le composant de liste des réservations.
 */

export const ListReservations = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const [isCreateUpdateModalOpen, setIsCreateUpdateModalOpen] = useState(false);
  const [purpose, setPurpose] = useState('create');
  const [refreshData, setRefreshData] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pensionerId, setPensionerId] = useState(null);
  const [reservationId, setReservationId] = useState(null);
  const [reservations, setReservations] = useState([]);
  const [supervision, setSupervision] = useState({});
  const [customId, setCustomId] = useState(null);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [recipients, setRecipients] = useState([]);
  const [recipient, setRecipient] = useState([]);

  useEffect(() => {
    navigate({
      pathname,
      search: '?s=-date&p=1&pS=10'
    });
  }, []);

  const columns = useColumns({
    setIsCreateUpdateModalOpen,
    setPurpose,
    setCustomId,
    setIsModalOpen,
    setPensionerId,
    setReservationId,
    setSupervision,
    reservations,
    setRecipient,
    setRecipients,
    setIsEmailModalOpen
  });

  return (
    <>
      <ListResource
        tradKey="reservations"
        resourceName="reservations/form"
        columns={columns}
        resourceModelName="Reservation"
        withCustomCreateButton
        onDoubleClickAction={false}
        customCreateButtonAction={() => {
          setIsCreateUpdateModalOpen(true);
          setPurpose('create');
          form.resetFields();
        }}
        withCreateButton={false}
        forceRefresh={refreshData}
        customActionColumn
        setResourceData={setReservations}
        scroll={{ x: 'max-content' }}
        backIcon={false}
      />
      <CreateUpdateReservation
        isCreateUpdateModalOpen={isCreateUpdateModalOpen}
        setIsCreateUpdateModalOpen={setIsCreateUpdateModalOpen}
        purpose={purpose}
        setRefreshData={setRefreshData}
        refreshData={refreshData}
        customId={customId}
        form={form}
      />
      <ReservationModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        t={t}
        supervision={supervision}
        reservations={reservations}
        pensionerId={pensionerId}
        reservationId={reservationId}
        message={message}
        refreshData={refreshData}
        setRefreshData={setRefreshData}
        dispatchAPI={dispatchAPI}
        navigate={navigate}
      />

      {isEmailModalOpen && (
        <MailingModal
          isModalOpen={isEmailModalOpen}
          setIsModalOpen={setIsEmailModalOpen}
          recipients={recipients}
          recipient={recipient}
          customFormInstance={form}
          templateType="SEND_MAIL"
          collection="reservations"
        />
      )}
    </>
  );
};
