import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ListResource } from '../../components';
import { useColumns } from './columns';

export const ListSupervisionPrices = () => {
  const columns = useColumns();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    navigate({
      pathname,
      search: '?s=duration&p=1&pS=10'
    });
  }, []);

  return (
    <ListResource
      resourceName="supervisionprices"
      columns={columns}
      resourceModelName="Supervisionprice"
      withCreateButton={false}
      customActionColumn
      backIcon={false}
    />
  );
};
