import { useEffect, useState } from 'react';
import { Card, Button, Typography, Skeleton, Col, Row } from 'antd';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PageHeaderCustom } from '../../../components/PageHeader/PageHeader';
import { ContentCustom } from '../../../components/ContentCustom/ContentCustom';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { ConfirmReservationModal } from './ConfirmReservationModal';
import { getAnounces } from './utils/getAnounces';
import { convertToHTML } from '../../../utils/convertToHTML';

const { Title, Text } = Typography;

export const ListViewAds = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();
  const [anounces, setAnounces] = useState([]);
  const [isReservationModalOpen, setIsReservationModalOpen] = useState(false);
  const [reservations, setReservations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      await getAnounces({
        dispatchAPI,
        user,
        setAnounces,
        message,
        setReservations,
        setIsLoading
      });
    })();
  }, []);

  return (
    <>
      <PageHeaderCustom withoutBreadCrumb title={t('view-ads.title')} />
      <ContentCustom>
        <Skeleton loading={isLoading}>
          {anounces.length ? (
            <Row gutter={[0, 16]}>
              {anounces.map((anounce) => (
                <Col span={24} key={anounce.id}>
                  <Card
                    className="ad-list-card"
                    hoverable
                    onClick={() =>
                      navigate(
                        `show/${anounce?.supervision?._id}/${anounce?._id}`
                      )
                    }
                    title={
                      <div style={{ whiteSpace: 'wrap' }}>
                        {anounce?.supervision?.main_informations &&
                          t('view-ads.cards.title', {
                            reference: anounce?.supervision?.reference,
                            start_date:
                              anounce?.supervision?.main_informations
                                ?.start_date &&
                              dayjs(
                                anounce?.supervision?.main_informations
                                  ?.start_date
                              )
                                .subtract(1, 'day')
                                .format('DD/MM/YYYY'),
                            end_date:
                              anounce?.supervision.main_informations
                                ?.end_date &&
                              dayjs(
                                anounce?.supervision?.main_informations
                                  ?.end_date
                              ).format('DD/MM/YYYY')
                          })}
                      </div>
                    }
                  >
                    <Row gutter={[0, 8]}>
                      <Col span={24}>
                        <Row align="middle">
                          {t('view-ads.cards.city')}
                          <strong>
                            {anounce?.supervision?.address?.petsitting_address
                              ?.address?.city || '-'}
                          </strong>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row align="middle">
                          {t('view-ads.cards.state')}
                          <strong>
                            {anounce?.supervision?.address?.petsitting_address
                              ?.address?.state || '-'}
                          </strong>
                        </Row>
                      </Col>
                    </Row>
                    {convertToHTML({ htmlContent: anounce?.description })}
                    {reservations.some(
                      (reservation) =>
                        reservation?.supervision?._id ===
                        anounce?.supervision?._id
                    ) ? (
                      <Text>{t(`reservations.tags.WAITING`)}</Text>
                    ) : (
                      <div style={{ marginTop: '8px' }}>
                        <Button
                          onClick={() => setIsReservationModalOpen(true)}
                          type="primary"
                        >
                          {t('view-ads.buttons.consult')}
                        </Button>
                      </div>
                    )}
                  </Card>
                  {isReservationModalOpen && (
                    <ConfirmReservationModal
                      dispatchAPI={dispatchAPI}
                      setIsReservationModalOpen={setIsReservationModalOpen}
                      isReservationModalOpen={isReservationModalOpen}
                      message={message}
                      user={user}
                      navigate={navigate}
                      supervisionId={anounce?.supervision?._id}
                    />
                  )}
                </Col>
              ))}
            </Row>
          ) : (
            <Title level={1}>{t('view-ads.show.titles.no_anouncement')}</Title>
          )}
        </Skeleton>
      </ContentCustom>
    </>
  );
};
