import PropTypes from 'prop-types';
import { Form } from 'antd';
import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainer/CreateUpdateContainer';
import PetsittingAddressFields from '../../owners/form/petsittingAddressForm/PetsittingAddressFields';
import { useConfig } from './utils/petsittingAddressConfig';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useCustomSubmit } from '../utils/customSubmit';
import { editingLocked } from '../../../utils/editingLocked';
import { useSocketContext } from '../../../contexts/SocketContext';
import { EditingLocked } from '../../../components/EditingLocked/EditingLocked';

/**
 * Component for creating or updating a pet sitting address.
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.purpose - The purpose of the component (either "create" or "edit").
 * @returns {JSX.Element} The JSX element representing the CreateUpdatePetsittingAddress component.
 */
export const CreateUpdatePetsittingAddress = ({ purpose }) => {
  const [form] = Form.useForm();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { id } = useParams();
  const { profileLock } = useSocketContext();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [environmentType, setEnvironmentType] = useState([]);
  const [country, setCountry] = useState('');
  const [typeOfDwelling, setTypeOfDwelling] = useState('');
  const [petsittingAddressFileList, setPetsittingAddressFileList] = useState(
    []
  );

  const { onCreateResource, onGetResource, onUpdateResource } = useConfig(
    petsittingAddressFileList,
    setPetsittingAddressFileList,
    environmentType,
    setEnvironmentType,
    dispatchAPI,
    message,
    setCountry,
    setTypeOfDwelling
  );

  const { customSubmit, getResource } = useCustomSubmit({
    setIsSubmitting,
    onUpdateResource,
    onCreateResource,
    purpose,
    setIsLoading,
    onGetResource,
    form,
    id
  });

  useEffect(() => {
    if (purpose === 'edit' && id) {
      setIsLoading(true);
      (async () => {
        await getResource();
      })();
    }
  }, [getResource]);

  return (
    <>
      {editingLocked(profileLock, id) && (
        <EditingLocked
          t={t}
          resources={{
            button: 'my_petsitting_address',
            text: 'profile'
          }}
          navigate={navigate}
        />
      )}
      <CreateUpdateContainer
        purpose={purpose}
        customFormInstance={form}
        baseUrl="petsittingaddresses/form"
        customSubmit={customSubmit}
        resource="petsittingaddress"
        populate="dwelling_photos"
        formExtra={
          <PetsittingAddressFields
            fileList={petsittingAddressFileList}
            setFileList={setPetsittingAddressFileList}
            form={form}
            purpose={purpose}
            environmentType={environmentType}
            setEnvironmentType={setEnvironmentType}
            country={country}
            setCountry={setCountry}
            typeOfDwelling={typeOfDwelling}
            setTypeOfDwelling={setTypeOfDwelling}
          />
        }
        loadingFields={isLoading}
        customIsSubmiting={isSubmitting}
        disabled={editingLocked(profileLock, id)}
      />
    </>
  );
};

CreateUpdatePetsittingAddress.propTypes = {
  purpose: PropTypes.string.isRequired
};
