import { Radio } from 'antd';
import { useTranslation } from 'react-i18next';

/**
 * Returns a radio group component for reviews.
 * @function
 *
 * @returns {JSX.Element} The radio group component.
 */
export const getRadioGroup = () => {
  const { t } = useTranslation();
  const reviewEnums = [
    {
      value: 'EXCELLENT',
      label: t('owners.users_shows.review_drawer.form.answers.excellent')
    },
    {
      value: 'GOOD',
      label: t('owners.users_shows.review_drawer.form.answers.good')
    },
    {
      value: 'FAIR',
      label: t('owners.users_shows.review_drawer.form.answers.fair')
    },
    {
      value: 'BAD',
      label: t('owners.users_shows.review_drawer.form.answers.bad')
    }
  ];

  return (
    <Radio.Group>
      {reviewEnums.map(({ value, label }) => (
        <Radio key={value} value={value}>
          {label}
        </Radio>
      ))}
    </Radio.Group>
  );
};
