import { Tag, Popconfirm, Divider, Tooltip } from 'antd';
import {
  EditOutlined,
  WarningOutlined,
  ContainerOutlined,
  EyeOutlined,
  RollbackOutlined
} from '@ant-design/icons';
import { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../contexts/AuthContext';
import { PensionerStatus } from '../../utils/constants/tagColors';
import { useErrorMessage } from '../../utils/errorMessage';
import { useListResourceArchiving } from '../../utils/listResourceArchiving';
import { postalCodeFilter } from './utils/pensionersFilters';
import { genericSorter, statusSorter } from '../../utils/sorterMethods';

/**
 * Custom hook that returns columns and headers for the pensioners table.
 * @hook
 * @param {Function} setForceRefresh - Function to force refresh the table.
 * @returns {Object} - Object containing columns and headers.
 */
export const useColumns = (setForceRefresh) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { deleteResource, restoreResource } = useListResourceArchiving(
    setForceRefresh,
    'pensioners/archiving'
  );

  const setColumnHeaderStyle = () => ({
    onHeaderCell: () => ({
      style: {
        whiteSpace: 'nowrap'
      }
    })
  });

  const [enums, setEnums] = useState();
  const searchInput = useRef(null);

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/pensioners/enums' });
      setEnums(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getEnums();
    })();
  }, []);

  const columns = [
    {
      title: t('pensioners.form.reference'),
      key: 'reference',
      dataIndex: 'reference',
      width: '5%',
      sorter: true,
      ...setColumnHeaderStyle()
    },
    {
      title: t('pensioners.form.principal_pensioner'),
      key: 'last_name',
      render: (record) => {
        const civility = record?.civility
          ? t(`pensioners.tags.${record?.civility}`)
          : '';
        const lastName = record?.last_name || '';
        const firstName = record?.first_name || '';

        return `${civility} ${lastName} ${firstName}`;
      },
      sorter: (a, b) => genericSorter(a, b, ['last_name'])
    },
    {
      title: t('pensioners.form.secondary_pensioner'),
      key: 'secondary_profile.last_name',
      render: (record) => {
        const civility = record?.secondary_profile?.civility
          ? t(`pensioners.tags.${record?.secondary_profile?.civility}`)
          : '';
        const lastName = record?.secondary_profile?.last_name || '';
        const firstName = record?.secondary_profile?.first_name || '';

        return `${civility} ${lastName} ${firstName}`;
      },
      sorter: (a, b) => genericSorter(a, b, ['secondary_profile', 'last_name'])
    },
    {
      title: t('pensioners.form.address.city'),
      key: 'address.city',
      dataIndex: 'address',
      render: (address) => address?.city,
      sorter: true,
      ...setColumnHeaderStyle()
    },
    {
      title: t('pensioners.form.address.postal_code'),
      key: 'address.postal_code',
      dataIndex: 'address',
      ...postalCodeFilter(searchInput, t),
      render: (address) => address?.postal_code,
      sorter: true,
      ...setColumnHeaderStyle()
    },
    {
      title: t('pensioners.form.department'),
      key: 'address.state',
      dataIndex: 'address',
      render: (address) => address?.state,
      sorter: (a, b) => genericSorter(a, b, ['address', 'state']),
      ...setColumnHeaderStyle()
    },
    {
      title: t('pensioners.form.phone_number_full'),
      key: 'phone_number.number',
      dataIndex: 'phone_number',
      render: (phone_number) => phone_number?.number,
      sorter: true,
      ...setColumnHeaderStyle()
    },
    {
      title: t('pensioners.form.cell_phone_number_full'),
      key: 'cell_phone_number.number',
      dataIndex: 'cell_phone_number',
      render: (cell_phone_number) => cell_phone_number?.number,
      sorter: true,
      ...setColumnHeaderStyle()
    },
    {
      title: t('pensioners.form.email'),
      key: 'email',
      dataIndex: 'email',
      sorter: (a, b) => genericSorter(a, b, ['email']),
      ...setColumnHeaderStyle()
    },
    {
      title: t('pensioners.form.status'),
      key: 'status',
      dataIndex: ['status'],
      render: (status) =>
        status ? (
          <Tag color={PensionerStatus[status]}>
            {t(`pensioners.tags.${status}`, {
              defaultValue: status
            })}
          </Tag>
        ) : (
          'N/R'
        ),
      filters: enums?.status?.map((r) => ({
        text: t(`pensioners.tags.${r}`),
        value: r
      })),
      sorter: (a, b) => statusSorter(a, b, 'pensioners.tags', t)
    },
    {
      key: 'actions',
      align: 'right',
      fixed: 'right',
      width: 100,
      render: (record) => (
        <>
          <Tooltip
            title={t('datatable.column.action.show.hover')}
            placement="left"
          >
            <Link to={{ pathname: `/pensioners/show/${record._id}` }}>
              <EyeOutlined style={{ fontSize: 18 }} />
            </Link>
          </Tooltip>
          <Divider type="vertical" />
          <Tooltip
            title={t('datatable.column.action.edit.hover')}
            placement="left"
          >
            <Link to={{ pathname: `/pensioners/edit/${record._id}` }}>
              <EditOutlined style={{ fontSize: 18 }} />
            </Link>
          </Tooltip>
          <>
            <Divider type="vertical" />
            {!record.is_archived ? (
              <Popconfirm
                title={t('datatable.column.action.archive.title')}
                okText={t('datatable.column.action.archive.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.archive.cancel')}
                onConfirm={() => deleteResource(record?._id)}
                icon={<WarningOutlined />}
              >
                <Tooltip
                  title={t('datatable.column.action.archive.hover')}
                  placement="left"
                >
                  <ContainerOutlined
                    style={{ color: '#b51010', fontSize: 18 }}
                    type="delete"
                  />
                </Tooltip>
              </Popconfirm>
            ) : (
              <Popconfirm
                title={t('datatable.column.action.unarchived.title')}
                okText={t('datatable.column.action.unarchived.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.unarchived.cancel')}
                onConfirm={() => restoreResource(record?._id)}
                icon={<WarningOutlined />}
              >
                <Tooltip
                  title={t('datatable.column.action.unarchived.hover')}
                  placement="left"
                >
                  <RollbackOutlined
                    style={{ color: '#b51010', fontSize: 18 }}
                  />
                </Tooltip>
              </Popconfirm>
            )}
          </>
        </>
      )
    }
  ];

  return { columns };
};
