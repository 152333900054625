import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import { useEffect, useState } from 'react';
import { ContentCustom, PageHeaderCustom } from '../../../components';
import { ListInvoiceDocuments } from './ListInvoiceDocuments';
import { ListContractDocuments } from './ListContractDocuments';
import { useCallsAPI } from '../utils/callAPI';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { getContracts } from './utils/getContracts';
import { getInvoices } from './utils/getinvoices';

export const ShowMyDocuments = () => {
  const { t } = useTranslation();
  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();
  const [contracts, setContracts] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filterID, setFilterID] = useState(null);

  const { getProfile } = useCallsAPI({
    setFunction: (data) => {
      setContracts(
        data[0]?.documents?.filter((doc) => doc.type.includes('contract'))
      );
      setInvoices(
        data[0]?.documents?.filter((doc) => doc.type.includes('invoice'))
      );
    },
    setIsLoading
  });

  useEffect(async () => {
    await getProfile();
    await getInvoices({ dispatchAPI, user, setInvoices, message });
    await getContracts({
      user,
      dispatchAPI,
      setFilterID,
      setContracts,
      message
    });
  }, []);

  const tabsItems = [
    {
      label: t('dashboard.show.document.contracts'),
      key: 1,
      children: (
        <ListContractDocuments
          documents={contracts}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          filterID={filterID}
          user={user}
        />
      )
    },
    {
      label: t('dashboard.show.document.invoices'),
      key: 2,
      children: (
        <ListInvoiceDocuments
          documents={invoices}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          user={user}
        />
      )
    }
  ];

  return (
    <>
      <PageHeaderCustom
        withoutBreadCrumb
        title={t('dashboard.cards_titles.my_documents')}
      />
      <ContentCustom>
        <Tabs defaultActiveKey="1" type="card" size="small" items={tabsItems} />
      </ContentCustom>
    </>
  );
};
